<template>
  <div>
    <d-body-top-bar :title="$t('views.subscriptions.subscriptions-list')">
      <div slot="tree">
        <label class="bg-doinsport-primary ml-1"> {{ $t('general.actions.list') }}</label>
      </div>
      <div slot="icons">
      </div>
    </d-body-top-bar>
    <subscription-list/>
  </div>
</template>
<script>
export default {
  components: {
    SubscriptionList: () => import('./subscription-list/Index'),
    CreditList: () => import('./credit-list/Index')
  },
};
</script>
